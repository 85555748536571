<template>
  <div class="">
    <b-row>
      <b-col md="2">
        <b-card style="border-left: 4px solid #e83b04" class="mt-2 text-center">
          <h2>
            <b> Kolom {{ currentNoKolom }}</b>
          </h2>
        </b-card>
        <b-card class="mt-2 text-center">
          <h4>Kolom Ujian</h4>
          <hr />
          <vue-custom-scrollbar class="scroll-area" :settings="settings">
            <span class="" v-for="(item, i) in dataKolom" :key="i">
              <b-button
                v-if="currentKolom.soal_id == item.soal_id"
                style="cursor: default"
                variant="primary"
                class="m-1"
                size="lg"
                >{{ i + 1 }}</b-button
              >
              <b-button
                v-else-if="i < currentNoKolom"
                style="cursor: default"
                variant="outline-primary"
                class="m-1"
                size="md"
                ><feather-icon icon="CheckIcon"></feather-icon>
              </b-button>
              <b-button
                v-else
                style="cursor: default"
                variant="outline-primary"
                class="m-1"
                size="md"
                >{{ i + 1 }}</b-button
              >
            </span>
          </vue-custom-scrollbar>
        </b-card>
      </b-col>
      <b-col md="10">
        <div class="mt-2">
          <b-card bg-variant="primary">
            <b-row class="justify-content-beetwen">
              <b-col md="5">
                <h2 class="text-white mt-2">
                  Soal Dijawab
                  <b-button variant="light"
                    >{{ currentNo }}/{{ currentSoals.length }}</b-button
                  >
                </h2>
                <h6 class="text-light mt-1">{{ currentKolom.name }}</h6>
              </b-col>

              <b-col md="4"></b-col>
              <b-col md="3">
                <b-card no-body>
                  <b-card-body class="ml-2">
                    <feather-icon icon="ClockIcon" class="mr-50" />
                    <span class="font-weight-bold">Sisa Waktu </span>
                    <h2 class="text-primary mt-1">{{ displayTimer }}</h2>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="text-center">
            <b-button variant="primary"
              ><h1 class="mt-1 mb-1 text-white">
                {{ angka1(currentSoal.content) }}
              </h1></b-button
            >
            <span class="ml-3 mr-3 h1">{{
              operator(currentSoal.content)
            }}</span>
            <b-button variant="primary"
              ><h1 class="mt-1 mb-1 text-white">
                {{ angka2(currentSoal.content) }}
              </h1></b-button
            >
          </b-card>
          <b-card>
            <center>
              <b-button
                size="lg"
                variant="outline-dark"
                class="mr-1"
                @click="isiGenap"
                >Genap</b-button
              >
              <b-button size="lg" variant="outline-dark" @click="isiGanjil"
                >Ganjil</b-button
              >
            </center>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <br />
    <br />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
} from "bootstrap-vue";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import FormInputContextualStatesVue from "@/views/forms/form-element/form-input/FormInputContextualStates.vue";
export default {
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    HorizontalScroll,
    vueCustomScrollbar,
  },

  data() {
    return {
      selected: null,
      selecteds: [],
      is_doubt: false,
      displayTimer: null,
      soals: [],
      currentSoal: null,
      currentOption: [],
      loading: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      timer: null,
    };
  },

  computed: {
    detailUjian() {
      return this.$store.state.ujian.detailUjian;
    },
    currentUjian() {
      return this.$store.state.ujian.currentUjian;
    },
    currentUserAnswer() {
      return this.$store.state.ujian.currentUserAnswer;
    },
    currentNo() {
      return this.$store.state.ujian.currentNo;
    },
    currentNoKolom() {
      return this.$store.state.ujian.currentNoKolom;
    },
    currentSoals() {
      return this.$store.state.ujian.currentSoals;
    },
    currentKolom() {
      return this.$store.state.ujian.currentKolom;
    },
    dataKolom() {
      return this.$store.state.ujian.dataKolom;
    },
  },

  watch: {
    selected(event) {
      this.selectPg(event);
    },
    selecteds: {
      handler: function (val, oldVal) {
        this.selectPgs(val);
      },
      deep: true,
    },
    "$store.state.ujian.currentSoal"(soal) {
      if (soal) {
        this.currentSoal = soal;
      }
    },
  },

  methods: {
    angka1(text) {
      let angka1 = text.split(" ");
      angka1 = angka1.length == 1 ? text.split("") : angka1;

      return angka1[0];
    },
    angka2(text) {
      let angka2 = text.split(" ");
      angka2 = angka2.length == 1 ? text.split("") : angka2;
      return angka2[2];
    },
    operator(text) {
      let operator = text.split(" ");
      operator = operator.length == 1 ? text.split("") : operator;
      return operator[1];
    },
    setTimer() {
      const endDateTime = new Date();
      endDateTime.setSeconds(
        endDateTime.getSeconds() + parseInt(this.currentKolom.timer_second)
      );
      // endDateTime.setMinutes(endDateTime.getMinutes() + 1);
      const end = localStorage.endtime
        ? new Date(localStorage.endtime)
        : endDateTime;

      if (!localStorage.endtime) {
        localStorage.endtime = endDateTime;
      }
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let result = [];
      // let timer;

      let showRemaining = async () => {
        result = [];
        const now = new Date();
        const distance = end - now;

        if (distance < 0) {
          this.isiNull();
          this.displayTimer = null;
          localStorage.removeItem("endtime");
          return;
        }

        const days = Math.floor(distance / _day);
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);
        if (hours > 0) {
          result.push(hours);
        }

        result.push(minutes < 10 ? `0${minutes}` : minutes);
        result.push(seconds < 10 ? `0${seconds}` : seconds);

        this.displayTimer = result.join(":");
      };

      this.timer = setInterval(showRemaining, 1000);
    },
    updateDataKolom(completeAllSubSoal = false) {
      // update data kolom
      let prevDataKolom = Object.assign([], this.dataKolom);
      prevDataKolom = prevDataKolom.map((kolom) => {
        kolom.sub_soals = kolom.sub_soals.map((soal) => {
          if (this.currentSoal.sub_soal_id == soal.sub_soal_id) {
            soal.is_complete = true;
          }

          if (
            completeAllSubSoal &&
            this.currentKolom.soal_id == kolom.soal_id
          ) {
            soal.is_complete = true;
          }

          return soal;
        });
        return kolom;
      });

      this.$store.commit("ujian/SET_DATA_KOLOM", prevDataKolom);
    },
    async isiGenap() {
      var opsi = 0;
      let indexKolom = this.dataKolom.findIndex((item) => {
        return item.soal_id == this.currentKolom.soal_id;
      });
      let indexSoal = this.currentSoals.findIndex((item) => {
        return item.sub_soal_id == this.currentSoal.sub_soal_id;
      });
      let totalKolom = this.dataKolom.length - 1;
      let totalSoal = this.currentSoals.length - 1;
      const isAnswerCorrect = this.currentSoal.secret_key == opsi;
      if (isAnswerCorrect) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Benar`,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Salah`,
            icon: "XIcon",
            variant: "danger",
          },
        });
      }
      await this.updateDataKolom();
      await this.kirimSoal(opsi);
      const isLastKolom = indexKolom == totalKolom && indexSoal == totalSoal;
      if (isLastKolom) {
        clearInterval(this.timer);
        localStorage.removeItem("endtime");
        this.displayTimer = null;
        localStorage.setItem("endUjian", true);
        this.akhiriUjian();
      } else {
        const isLastSoal = indexSoal == totalSoal;
        if (isLastSoal) {
          // ganti ke kolom baru, timer nya pun ganti
          let nextNoKolom = this.currentNoKolom + 1;
          let nextIndex = nextNoKolom - 1;
          const nextKolom = this.dataKolom[nextIndex];
          this.$store.commit("ujian/SET_CURRENT_KOLOM", nextKolom);
          this.$store.commit("ujian/SET_CURRENT_SOALS", nextKolom.sub_soals);
          this.$store.commit("ujian/SET_CURRENT_NO_KOLOM", nextNoKolom);
          this.$store.commit("ujian/SET_CURRENT_NO", 0);
          const newSoal = this.currentSoals[0];
          this.$store.commit("ujian/SET_CURRENT_SOAL", newSoal);
          clearInterval(this.timer);
          localStorage.removeItem("endtime");
          this.displayTimer = null;
          this.setTimer();
        } else {
          let nextNo = this.currentNo + 1;
          const nextSoal = this.currentSoals[nextNo];
          this.$store.commit("ujian/SET_CURRENT_NO", nextNo);
          this.$store.commit("ujian/SET_CURRENT_SOAL", nextSoal);
        }
      }
    },
    async isiGanjil() {
      var opsi = 1;
      let indexKolom = this.dataKolom.findIndex((item) => {
        return item.soal_id == this.currentKolom.soal_id;
      });
      let indexSoal = this.currentSoals.findIndex((item) => {
        return item.sub_soal_id == this.currentSoal.sub_soal_id;
      });
      let totalKolom = this.dataKolom.length - 1;
      let totalSoal = this.currentSoals.length - 1;
      const isAnswerCorrect = this.currentSoal.secret_key == opsi;
      if (isAnswerCorrect) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Benar`,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Salah`,
            icon: "XIcon",
            variant: "danger",
          },
        });
      }

      await this.updateDataKolom();
      await this.kirimSoal(opsi);
      const isLastKolom = indexKolom == totalKolom && indexSoal == totalSoal;
      if (isLastKolom) {
        clearInterval(this.timer);
        localStorage.removeItem("endtime");
        this.displayTimer = null;
        localStorage.setItem("endUjian", true);
        this.akhiriUjian();
      } else {
        // check apakah soal terakhir di n kolom
        const isLastSoal = indexSoal == totalSoal;
        if (isLastSoal) {
          let nextNoKolom = this.currentNoKolom + 1;
          let nextIndex = nextNoKolom - 1;
          const nextKolom = this.dataKolom[nextIndex];
          this.$store.commit("ujian/SET_CURRENT_KOLOM", nextKolom);
          this.$store.commit("ujian/SET_CURRENT_SOALS", nextKolom.sub_soals);
          this.$store.commit("ujian/SET_CURRENT_NO_KOLOM", nextNoKolom);
          this.$store.commit("ujian/SET_CURRENT_NO", 0);
          const newSoal = this.currentSoals[0];
          this.$store.commit("ujian/SET_CURRENT_SOAL", newSoal);
          clearInterval(this.timer);
          localStorage.removeItem("endtime");
          this.displayTimer = null;
          this.setTimer();
        } else {
          let nextNo = this.currentNo + 1;
          const nextSoal = this.currentSoals[nextNo];
          this.$store.commit("ujian/SET_CURRENT_NO", nextNo);
          this.$store.commit("ujian/SET_CURRENT_SOAL", nextSoal);
        }
      }
    },
    async isiNull() {
      var opsi = null;
      let indexKolom = this.dataKolom.findIndex((item) => {
        return item.soal_id == this.currentKolom.soal_id;
      });
      let indexSoal = this.currentSoals.findIndex((item) => {
        return item.sub_soal_id == this.currentSoal.sub_soal_id;
      });
      let totalKolom = this.dataKolom.length - 1;
      let totalSoal = this.currentSoals.length - 1;
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Waktu Habis`,
          icon: "ClockIcon",
          variant: "danger",
        },
      });
      await this.updateDataKolom(true);
      await this.kirimSoal(opsi);
      if (indexKolom == totalKolom) {
        clearInterval(this.timer);
        localStorage.removeItem("endtime");
        this.displayTimer = null;
        localStorage.setItem("endUjian", true);
        this.akhiriUjian();
      } else {
        // ganti ke kolom baru, timer nya pun ganti
        let nextNoKolom = this.currentNoKolom + 1;
        let nextIndex = nextNoKolom - 1;
        const nextKolom = this.dataKolom[nextIndex];
        this.$store.commit("ujian/SET_CURRENT_KOLOM", nextKolom);
        this.$store.commit("ujian/SET_CURRENT_SOALS", nextKolom.sub_soals);
        this.$store.commit("ujian/SET_CURRENT_NO_KOLOM", nextNoKolom);
        this.$store.commit("ujian/SET_CURRENT_NO", 0);
        const newSoal = this.currentSoals[0];
        this.$store.commit("ujian/SET_CURRENT_SOAL", newSoal);
        this.displayTimer = null;
        localStorage.removeItem("endtime");
        clearInterval(this.timer);
        this.setTimer();
      }
    },
    kirimSoal(answer) {
      var payload = {
        hasil_id: this.currentUjian.id,
        soal_id: this.currentKolom.soal_id,
        sub_soal_id: this.currentSoal.sub_soal_id,
        answer: answer,
      };
      // }
      this.$store
        .dispatch("ujian/kirimJawabanSoal", [payload])
        .then((res) => {
          this.loading = false;
          this.$store.commit("ujian/SET_CURRENT_UJIAN", res.data);

          return Promise.resolve();
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          return Promise.reject();
        });
    },
    moveSoal(soal, index) {
      this.loading = true;
      if (this.selected != null) {
        this.kirimSoal();
        if (soal.soal.length > 0) {
          this.$store.commit("ujian/SET_CURRENT_SOAL", soal.soal[0]);
        } else {
          this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
        }
      } else {
        this.loading = false;
        if (soal.soal.length > 0) {
          this.$store.commit("ujian/SET_CURRENT_SOAL", soal.soal[0]);
        } else {
          this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
        }
      }
      this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", soal);
      this.$store.commit("ujian/SET_CURRENT_NO", index + 1);
      this.cekSoal();
    },
    cekSoal() {
      let currentUserAnswer = this.currentUserAnswer;
      let answer = currentUserAnswer.answer;
      if (answer != null) {
        if (currentUserAnswer.soal[0].type == "pg") {
          this.selected = answer;
        } else if (currentUserAnswer.soal[0].type == "multiple_choice") {
          let answerMultiple = answer.split(",");
          this.selecteds = answerMultiple;
        } else {
          (this.selected = null), (this.selecteds = []);
        }
      }
    },
    cekSoalPernyataan() {
      let answer = this.currentUserAnswer.answer;
      let result = [];
      let pernyataan = answer.split(",");
      pernyataan.forEach((v) => {
        let hasil = v.split(`:`);
        let content = this.currentOption.find((res) => {
          return res.id == hasil[0];
        });
        result.push({
          id: hasil[0],
          soal_id: content.soal_id,
          option: content.option,
          content: content.content,
          is_correct: content.is_correct,
          is_true_or_false: content.is_true_or_false,
          created_at: content.created_at,
          updated_at: content.updated_at,
          file: content.file,
          selected: hasil[1],
        });
      });
      this.currentOption = result;
    },
    akhiriUjian() {
      this.$swal({
        title: "Ujian telah selesai!",
        text: "Jawaban anda tersimpan di data kami!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Akhiri",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.loading = true;
        let payload = {
          hasil_id: this.currentUjian.id,
        };
        this.$store
          .dispatch("ujian/selesaiUjian", payload)
          .then((res) => {
            localStorage.removeItem("endtime");
            this.loading = false;
            this.$store.commit("ujian/SET_DATA_KOLOM", []);
            this.$store.commit("ujian/SET_CURRENT_SOALS", null);
            this.$store.commit("ujian/SET_CURRENT_SOAL", null);
            this.$store.commit("ujian/SET_CURRENT_KOLOM", null);
            this.$store.commit("ujian/SET_CURRENT_UJIAN", null);
            this.$store.commit("ujian/SET_CURRENT_NO", null);
            this.$store.commit("ujian/SET_CURRENT_NO_KOLOM", null);
            this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", res.data);
            this.$router.push({
              name: "hasil-ujian",
              query: { j: this.$route.query?.j },
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
        this.$swal({
          icon: "success",
          title: "Anda berhasil mengerjakan ujian ini!",
          text: "Semoga hasilnya memuaskan :)",
          allowOutsideClick: true,
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        });
      });
    },
    getLastIndexSoal(subSoals = []) {
      if (subSoals.length < 1) return 0;

      // get last soal berdasarkan terakhir yang is_complete false
      const lastCompleteIndex = subSoals.findLastIndex(
        (soal) => soal.is_complete
      );
      if (lastCompleteIndex < 0) return 0;

      // check apakah incplete index itu last soal
      const isLastSoal = lastCompleteIndex == subSoals.length - 1;
      if (isLastSoal) return lastCompleteIndex;

      return lastCompleteIndex + 1;
    },
    getLastIndexKolom() {
      // menentukan last index kolom
      // berdasarkan prop is_complete dari setiap kolom prop sub_soals
      const lastIndex = this.dataKolom.findLastIndex((kolom) => {
        const completeAllSubSoal = kolom.sub_soals.every(
          (soal) => soal.is_complete
        );

        return completeAllSubSoal;
      });

      if (lastIndex < 0) return 0;

      // check apakah last index merupakan kolom terakhir
      const isLastKolom = lastIndex == this.dataKolom.length - 1;
      if (isLastKolom) return lastIndex;

      return lastIndex + 1;
    },
  },
  created() {
    let lastQuestion = this.currentUjian.last_question_number;
    let lastSoal = this.currentUjian.last_sub_soal_number;
    let indexLastQuestion = this.getLastIndexKolom();
    // let indexLastQuestion = this.getLastIndexKolom();
    let currentKolom = this.dataKolom[indexLastQuestion];
    let indexLastSoal = this.getLastIndexSoal(currentKolom.sub_soals);

    this.$store.commit("ujian/SET_CURRENT_KOLOM", currentKolom);

    this.$store.commit("ujian/SET_CURRENT_SOALS", currentKolom.sub_soals);

    this.$store.commit(
      "ujian/SET_CURRENT_NO_KOLOM",
      this.getLastIndexKolom() + 1
    );

    this.$store.commit("ujian/SET_CURRENT_NO", indexLastSoal);

    const firstSoal = currentKolom.sub_soals[indexLastSoal];

    this.$store.commit("ujian/SET_CURRENT_SOAL", firstSoal);

    this.setTimer();
  },
};
</script>

<style>
@media only screen and (min-width: 774px) {
  .scroll-area {
    height: 260px;
  }
}
</style>
