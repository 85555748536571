var render = function () {
  var _vm$detailUjian, _vm$currentSoal$file, _vm$currentSoal$file2, _vm$currentSoal$file3, _vm$currentSoal$file4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "mt-2"
  }, [_c('b-card', {
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-beetwen"
  }, [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('h2', {
    staticClass: "text-white mt-2"
  }, [_vm._v("Soal Nomer " + _vm._s(_vm.currentNo))]), _c('h6', {
    staticClass: "text-light mt-1"
  }, [_vm._v(_vm._s((_vm$detailUjian = _vm.detailUjian) === null || _vm$detailUjian === void 0 ? void 0 : _vm$detailUjian.nama_ujian))])]), _c('b-col', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "ml-2 mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Sisa Waktu ")]), _c('h2', {
    staticClass: "text-primary mt-1"
  }, [_vm._v(_vm._s(_vm.displayTimer))])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-1"
  }, [_c('section', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.akhiriUjian
    }
  }, [_c('div', {
    staticClass: "ml-2 mr-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "LogInIcon",
      "size": "20"
    }
  }), _vm._v(" Akhiri ")], 1)])], 1)]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-10"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('horizontal-scroll', _vm._l(_vm.dataSoal, function (item, i) {
    return _c('span', {
      key: i
    }, [_c('b-button', {
      staticClass: "ml-1 mt-1 mb-1",
      attrs: {
        "variant": _vm.getVariantButton(item)
      },
      on: {
        "click": function click($event) {
          return _vm.moveSoal(item, i);
        }
      }
    }, [_vm._v(_vm._s(i + 1) + " ")])], 1);
  }), 0)], 1)], 1)], 1)])]), _c('b-card', [_vm.currentSoal ? _c('div', {}, [_c('div', {
    staticClass: "mb-2",
    domProps: {
      "innerHTML": _vm._s(_vm.currentSoal.content)
    }
  }), _vm.currentSoal.file ? _c('section', [_vm.getTypeFile((_vm$currentSoal$file = _vm.currentSoal.file) === null || _vm$currentSoal$file === void 0 ? void 0 : _vm$currentSoal$file.file) == 'document' ? _c('strong', {
    staticClass: "d-block"
  }, [_c('i', [_vm._v("Lampiran:")])]) : _vm._e(), _c('section', {
    class: _vm.getTypeFile((_vm$currentSoal$file2 = _vm.currentSoal.file) === null || _vm$currentSoal$file2 === void 0 ? void 0 : _vm$currentSoal$file2.file) == 'document' ? 'd-flex justify-content-start' : 'd-flex justify-content-center'
  }, [_c('file-handle', {
    attrs: {
      "class-name": _vm.getClassFile((_vm$currentSoal$file3 = _vm.currentSoal.file) === null || _vm$currentSoal$file3 === void 0 ? void 0 : _vm$currentSoal$file3.file),
      "file_path": (_vm$currentSoal$file4 = _vm.currentSoal.file) === null || _vm$currentSoal$file4 === void 0 ? void 0 : _vm$currentSoal$file4.file
    },
    on: {
      "onClick": _vm.onClickAttachment
    }
  })], 1)]) : _vm._e(), _c('hr'), _vm._l(_vm.currentOption, function (opsi, i) {
    var _opsi$file, _opsi$file2, _opsi$file3, _opsi$file4, _opsi$file5, _opsi$file6, _opsi$file7, _opsi$file8, _opsi$file9;

    return _c('div', {
      key: i
    }, [_vm.currentSoal.type == 'pg' ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-radio', {
      attrs: {
        "value": opsi.id,
        "name": "flavour-3a"
      },
      model: {
        value: _vm.selected,
        callback: function callback($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }), _c('b', [_vm._v(" " + _vm._s(opsi.option.toUpperCase()) + ". ")]), _c('section', [_c('div', {
      staticClass: "mb-1",
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    }), opsi.file ? _c('div', {}, [_vm.getTypeFile((_opsi$file = opsi.file) === null || _opsi$file === void 0 ? void 0 : _opsi$file.file) == 'document' ? _c('strong', {
      staticClass: "d-block"
    }, [_c('i', [_vm._v("Lampiran:")])]) : _vm._e(), _c('file-handle', {
      attrs: {
        "class-name": _vm.getClassFile((_opsi$file2 = opsi.file) === null || _opsi$file2 === void 0 ? void 0 : _opsi$file2.file),
        "file_path": (_opsi$file3 = opsi.file) === null || _opsi$file3 === void 0 ? void 0 : _opsi$file3.file
      },
      on: {
        "onClick": _vm.onClickAttachment
      }
    })], 1) : _vm._e()])], 1) : _vm.currentSoal.type == 'multiple_choice' ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-checkbox', {
      attrs: {
        "value": opsi.id,
        "name": "flavour-3a"
      },
      model: {
        value: _vm.selecteds,
        callback: function callback($$v) {
          _vm.selecteds = $$v;
        },
        expression: "selecteds"
      }
    }), _c('b', [_vm._v(" " + _vm._s(opsi.option.toUpperCase()) + ". ")]), _c('section', [_c('div', {
      staticClass: "mb-1",
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    }), opsi.file ? _c('div', {}, [_vm.getTypeFile((_opsi$file4 = opsi.file) === null || _opsi$file4 === void 0 ? void 0 : _opsi$file4.file) == 'document' ? _c('strong', {
      staticClass: "d-block"
    }, [_c('i', [_vm._v("Lampiran:")])]) : _vm._e(), _c('file-handle', {
      attrs: {
        "class-name": _vm.getClassFile((_opsi$file5 = opsi.file) === null || _opsi$file5 === void 0 ? void 0 : _opsi$file5.file),
        "file_path": (_opsi$file6 = opsi.file) === null || _opsi$file6 === void 0 ? void 0 : _opsi$file6.file
      },
      on: {
        "onClick": _vm.onClickAttachment
      }
    })], 1) : _vm._e()])], 1) : _vm.currentSoal.type == 'benar_salah' ? _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    }), _c('section', [_c('div', {
      staticClass: "mb-1",
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    }), opsi.file ? _c('div', {}, [_vm.getTypeFile((_opsi$file7 = opsi.file) === null || _opsi$file7 === void 0 ? void 0 : _opsi$file7.file) == 'document' ? _c('strong', {
      staticClass: "d-block"
    }, [_c('i', [_vm._v("Lampiran:")])]) : _vm._e(), _c('file-handle', {
      attrs: {
        "class-name": _vm.getClassFile((_opsi$file8 = opsi.file) === null || _opsi$file8 === void 0 ? void 0 : _opsi$file8.file),
        "file_path": (_opsi$file9 = opsi.file) === null || _opsi$file9 === void 0 ? void 0 : _opsi$file9.file
      },
      on: {
        "onClick": _vm.onClickAttachment
      }
    })], 1) : _vm._e()])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "value": "benar",
        "name": "flavour-3a"
      },
      model: {
        value: opsi.is_true_or_false,
        callback: function callback($$v) {
          _vm.$set(opsi, "is_true_or_false", $$v);
        },
        expression: "opsi.is_true_or_false"
      }
    }, [_vm._v("Benar ")]), _c('b-form-checkbox', {
      staticClass: "ml-1",
      attrs: {
        "value": "salah",
        "name": "flavour-3a"
      },
      model: {
        value: opsi.is_true_or_false,
        callback: function callback($$v) {
          _vm.$set(opsi, "is_true_or_false", $$v);
        },
        expression: "opsi.is_true_or_false"
      }
    }, [_vm._v("Salah ")])], 1)]) : _vm._e(), _c('hr')]);
  })], 2) : _c('div', {}, [_c('center', {
    staticClass: "text-danger"
  }, [_vm._v("Tidak Ada")])], 1)])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mt-4 mb-2"
  }, [_c('b-button', {
    staticClass: "btn-sm",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary",
      "disabled": _vm.currentNo == 1
    },
    on: {
      "click": function click($event) {
        return _vm.prev();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _vm._v("Sebelumnya")], 1), _c('b-button', {
    staticClass: "btn-sm ml-1 mr-1",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "flavour-3a"
    },
    model: {
      value: _vm.is_doubt,
      callback: function callback($$v) {
        _vm.is_doubt = $$v;
      },
      expression: "is_doubt"
    }
  }, [_vm._v("Ragu-ragu ")])], 1), _c('b-button', {
    staticClass: "btn-sm d-flex flex-column flex-sm-row align-items-center",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary",
      "disabled": _vm.currentNo >= _vm.dataSoal.length
    },
    on: {
      "click": function click($event) {
        return _vm.next();
      }
    }
  }, [_c('span', {
    staticClass: "d-none d-md-block"
  }, [_vm._v("Selanjutnya")]), _c('feather-icon', {
    staticClass: "ml-50",
    attrs: {
      "icon": "ArrowRightIcon"
    }
  }), _c('span', {
    staticClass: "d-block d-md-none"
  }, [_vm._v("Selanjutnya")])], 1)], 1), _c('br'), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }