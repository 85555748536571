<template>
  <div class="">
    <div class="mt-2">
      <b-card bg-variant="primary">
        <b-row class="justify-content-beetwen">
          <b-col cols="10">
            <h2 class="text-white mt-2">Soal Nomer {{ currentNo }}</h2>
            <h6 class="text-light mt-1">{{ detailUjian?.nama_ujian }}</h6>
          </b-col>
          <b-col>
            <b-card no-body>
              <b-card-body class="ml-2 mt-1">
                <feather-icon icon="ClockIcon" class="mr-50" />
                <span class="font-weight-bold">Sisa Waktu </span>
                <h2 class="text-primary mt-1">{{ displayTimer }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-overlay :show="loading">
      <section>
        <div class="mb-2">
          <div class="col-sm-12 mb-1">
            <section class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class="mt-1 mb-1"
                @click="akhiriUjian"
              >
                <div class="ml-2 mr-2">
                  <feather-icon icon="LogInIcon" class="mr-50" size="20" />
                  Akhiri
                </div>
              </b-button>
            </section>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10">
              <b-card no-body>
                <b-card-body>
                  <horizontal-scroll>
                    <span class="" v-for="(item, i) in dataSoal" :key="i">
                      <b-button
                        :variant="getVariantButton(item)"
                        class="ml-1 mt-1 mb-1"
                        @click="moveSoal(item, i)"
                        >{{ i + 1 }}
                      </b-button>
                    </span>
                  </horizontal-scroll>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
        <b-card>
          <div class="" v-if="currentSoal">
            <div class="mb-2" v-html="currentSoal.content"></div>
            <section v-if="currentSoal.file">
              <strong
                v-if="getTypeFile(currentSoal.file?.file) == 'document'"
                class="d-block"
              >
                <i>Lampiran:</i></strong
              >
              <section
                :class="
                  getTypeFile(currentSoal.file?.file) == 'document'
                    ? 'd-flex justify-content-start'
                    : 'd-flex justify-content-center'
                "
              >
                <file-handle
                  :class-name="getClassFile(currentSoal.file?.file)"
                  :file_path="currentSoal.file?.file"
                  @onClick="onClickAttachment"
                />
              </section>
            </section>
            <hr />
            <div class="" v-for="(opsi, i) in currentOption" :key="i">
              <div class="d-flex" v-if="currentSoal.type == 'pg'">
                <b-form-radio
                  v-model="selected"
                  :value="opsi.id"
                  name="flavour-3a"
                ></b-form-radio>
                <!-- <input type="radio" name="option" class="option-input radio" v-model="selected" :value="opsi.id" /> -->
                <b> {{ opsi.option.toUpperCase() }}. </b>
                <section>
                  <div class="mb-1" v-html="opsi.content"></div>
                  <div class="" v-if="opsi.file">
                    <strong
                      v-if="getTypeFile(opsi.file?.file) == 'document'"
                      class="d-block"
                    >
                      <i>Lampiran:</i></strong
                    >
                    <file-handle
                      :class-name="getClassFile(opsi.file?.file)"
                      :file_path="opsi.file?.file"
                      @onClick="onClickAttachment"
                    />
                  </div>
                </section>
              </div>
              <div
                class="d-flex"
                v-else-if="currentSoal.type == 'multiple_choice'"
              >
                <b-form-checkbox
                  v-model="selecteds"
                  :value="opsi.id"
                  name="flavour-3a"
                >
                </b-form-checkbox>
                <b> {{ opsi.option.toUpperCase() }}. </b>
                <section>
                  <div class="mb-1" v-html="opsi.content"></div>
                  <div class="" v-if="opsi.file">
                    <strong
                      v-if="getTypeFile(opsi.file?.file) == 'document'"
                      class="d-block"
                    >
                      <i>Lampiran:</i></strong
                    >
                    <file-handle
                      :class-name="getClassFile(opsi.file?.file)"
                      :file_path="opsi.file?.file"
                      @onClick="onClickAttachment"
                    />
                  </div>
                </section>
              </div>
              <div
                class="d-flex justify-content-between align-items-center"
                v-else-if="currentSoal.type == 'benar_salah'"
              >
                <div class="d-flex align-items-center">
                  <span v-html="opsi.content"></span>
                  <section>
                    <div class="mb-1" v-html="opsi.content"></div>
                    <div class="" v-if="opsi.file">
                      <strong
                        v-if="getTypeFile(opsi.file?.file) == 'document'"
                        class="d-block"
                      >
                        <i>Lampiran:</i></strong
                      >
                      <file-handle
                        :class-name="getClassFile(opsi.file?.file)"
                        :file_path="opsi.file?.file"
                        @onClick="onClickAttachment"
                      />
                    </div>
                  </section>
                </div>
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="opsi.is_true_or_false"
                    value="benar"
                    name="flavour-3a"
                    >Benar
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="ml-1"
                    v-model="opsi.is_true_or_false"
                    value="salah"
                    name="flavour-3a"
                    >Salah
                  </b-form-checkbox>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="" v-else>
            <center class="text-danger">Tidak Ada</center>
          </div>
        </b-card>
      </section>
    </b-overlay>
    <div class="d-flex justify-content-between mt-4 mb-2">
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm"
        @click="prev()"
        :disabled="currentNo == 1"
      >
        <feather-icon icon="ArrowLeftIcon" class="mr-50" />Sebelumnya</b-button
      >
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm ml-1 mr-1"
      >
        <b-form-checkbox v-model="is_doubt" name="flavour-3a"
          >Ragu-ragu
        </b-form-checkbox></b-button
      >
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm d-flex flex-column flex-sm-row align-items-center"
        @click="next()"
        :disabled="currentNo >= dataSoal.length"
      >
        <span class="d-none d-md-block">Selanjutnya</span>
        <feather-icon icon="ArrowRightIcon" class="ml-50" />
        <span class="d-block d-md-none">Selanjutnya</span>
      </b-button>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import FileHandle from "@/views/components/soal/FileHandle.vue";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BOverlay,
    FileHandle,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BImg,
    HorizontalScroll,
  },

  data() {
    return {
      selected: null,
      selecteds: [],
      is_doubt: false,
      displayTimer: null,
      soals: [],
      dataSoal: [],
      currentSoal: null,
      currentOption: [],
      loading: false,
    };
  },

  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
    detailUjian() {
      return this.$store.state.ujian.detailUjian;
    },
    currentUjian() {
      return this.$store.state.ujian.currentUjian;
    },
    currentUserAnswer() {
      return this.$store.state.ujian.currentUserAnswer;
    },
    currentNo() {
      return this.$store.state.ujian.currentNo;
    },
  },

  watch: {
    selected(event) {
      this.selectPg(event);
    },
    selecteds: {
      handler: function (val, oldVal) {
        this.selectPgs(val);
      },
      deep: true,
    },
    "$store.state.ujian.currentSoal"(soal) {
      if (soal) {
        this.currentSoal = soal;
        this.currentOption = soal.options;
      }
    },
  },

  methods: {
    getClassFile(file_path) {
      const file = this.getTypeFile(file_path);
      if (file == "image") return "img-fluid";
      return "my-1";
    },
    onClickAttachment({ url, ext }) {
      if (["pdf", "pdfx", "doc", "docx", "csv", "xls", "xlsx"].includes(ext)) {
        window.open(url);
      }
    },
    getVariantButton(item) {
      if (item.is_doubt == 1) return "warning";
      if (item.answer != null) return "success";
      return "outline-secondary";
    },
    setTimer() {
      const endDateTime = new Date();
      endDateTime.setMinutes(
        endDateTime.getMinutes() + parseInt(this.detailUjian.timer)
      );
      const end = localStorage.endtime
        ? new Date(localStorage.endtime)
        : endDateTime;
      if (!localStorage.endtime) {
        localStorage.endtime = endDateTime;
      }
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let result = [];
      let timer;

      let showRemaining = async () => {
        result = [];
        const now = new Date();
        const distance = end - now;

        if (distance < 0) {
          clearInterval(timer);
          this.kirimSoal(() => {
            this.loading = true;
            let payload = {
              hasil_id: this.currentUjian.id,
            };
            this.$store
              .dispatch("ujian/selesaiUjian", payload)
              .then((res) => {
                this.loading = false;
                this.$store.commit("ujian/SET_CURRENT_SOAL", null);
                this.$store.commit("ujian/SET_CURRENT_UJIAN", null);
                this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", null);
                this.$store.commit("ujian/SET_CURRENT_NO", null);
                this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", res.data);
                this.$router.push({
                  name: "hasil-ujian",
                  query: { j: this.$route.query?.j },
                });
              })
              .catch((err) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error `,
                    icon: "XIcon",
                    variant: "danger",
                    text: err.response.data.message,
                  },
                });
              });
            this.displayTimer = null;
            localStorage.removeItem("endtime");
            return;
          });
        }

        const days = Math.floor(distance / _day);
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);
        if (hours > 0) {
          result.push(hours);
        }

        result.push(minutes < 10 ? `0${minutes}` : minutes);
        result.push(seconds < 10 ? `0${seconds}` : seconds);

        this.displayTimer = result.join(":");
      };

      timer = setInterval(showRemaining, 1000);
    },
    selectPg(index) {},
    selectPgs(index) {
      let string = this.selecteds.join(",");
      let result = [];
      this.selecteds.forEach((v) => {
        result.push(`${v}:benar`);
      });
      let pernyataan = result.join(",");
      let array = string.split(",");
    },
    updateInitSoal(dataSoal) {
      const currentUjian = Object.assign({}, this.currentUjian);
      currentUjian.user_answer = dataSoal;
      this.$store.commit("ujian/SET_CURRENT_UJIAN", currentUjian);
    },
    kirimSoal(callbackFunction = null) {
      if (this.currentSoal.type == "pg") {
        let answer = this.selected;
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };

        if (this.is_doubt) {
          payload.is_doubt = this.is_doubt ? 1 : 0;
        }

        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then(async (res) => {
            this.loading = false;
            this.selected = null;
            this.is_doubt = false;
            this.dataSoal = res.data;
            await this.updateInitSoal(res.data);
            if (callbackFunction) {
              callbackFunction();
            }
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      } else if (this.currentSoal.type == "multiple_choice") {
        let answer = this.selecteds.join(",");
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };

        if (this.is_doubt) {
          payload.is_doubt = this.is_doubt ? 1 : 0;
        }
        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then(async (res) => {
            this.loading = false;
            this.selected = null;
            this.dataSoal = res.data;
            this.is_doubt = false;
            await this.updateInitSoal(res.data);
            if (callbackFunction) {
              callbackFunction();
            }
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      } else if (this.currentSoal.type == "benar_salah") {
        // let anwerthis.
        let result = [];
        this.currentOption.forEach((v) => {
          result.push(`${v.id}:${v.is_true_or_false}`);
        });
        let answer = result.join(",");
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };

        if (this.is_doubt) {
          payload.is_doubt = this.is_doubt ? 1 : 0;
        }
        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then(async (res) => {
            this.loading = false;
            this.selected = null;
            this.dataSoal = res.data;
            // update current ujian answer
            await this.updateInitSoal(res.data);

            this.is_doubt = false;
            if (callbackFunction) {
              callbackFunction();
            }
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    moveSoal(soal, index) {
      this.loading = true;
      this.kirimSoal(async () => {
        if (soal.soal.length > 0) {
          await this.$store.commit("ujian/SET_CURRENT_SOAL", soal.soal[0]);
          await this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", soal);
          await this.$store.commit("ujian/SET_CURRENT_NO", index + 1);
          this.cekSoal();
        } else {
          this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
        }
      });
    },
    cekSoal() {
      // untuk ngecek currentSoal apakah sudah adajawaban nya
      // kalo ada, tampilkan lagi
      let currentUserAnswer = this.currentUserAnswer;

      let answer = currentUserAnswer.answer;
      if (answer != null) {
        const soalPg = currentUserAnswer.soal[0].type == "pg",
          soalMultipleChoice =
            currentUserAnswer.soal[0].type == "multiple_choice",
          soalBenarSalah = currentUserAnswer.soal[0].type == "benar_salah";
        if (soalPg) {
          this.selected = answer;
        } else if (soalMultipleChoice) {
          let answerMultiple = answer.split(",");
          this.selecteds = answerMultiple;
        } else if (soalBenarSalah) {
          const answerOptions = answer.split(",");
          for (const option of answerOptions) {
            // option => "69:benar" atau "69:salah" atau "69:undefined"
            const [optionId, jawaban] = option.split(":");

            // check apakah optionId di current options ada
            let currentOption = Object.assign([], this.currentOption);
            currentOption = currentOption.map((item) => {
              const checkOption = item.id == optionId;
              const isValidAnswer = ["benar", "salah"].includes(jawaban);
              if (checkOption && isValidAnswer) {
                // add answer to item
                item.is_true_or_false = jawaban;
              }

              return item;
            });
            this.currentOption = currentOption;
          }
        } else {
          (this.selected = null), (this.selecteds = []);
        }
      }
    },
    cekSoalPernyataan() {
      let answer = this.currentUserAnswer.answer;
      let result = [];
      let pernyataan = answer.split(",");
      pernyataan.forEach((v) => {
        let hasil = v.split(`:`);
        let content = this.currentOption.find((res) => {
          return res.id == hasil[0];
        });
        result.push({
          id: hasil[0],
          soal_id: content.soal_id,
          option: content.option,
          content: content.content,
          is_correct: content.is_correct,
          is_true_or_false: content.is_true_or_false,
          created_at: content.created_at,
          updated_at: content.updated_at,
          file: content.file,
          selected: hasil[1],
        });
      });
      this.currentOption = result;
    },
    next() {
      this.loading = true;
      let nextNo = this.currentNo + 1;
      let nextIndex = nextNo - 1;
      const nextSoal = this.dataSoal[nextIndex];
      this.$store.commit("ujian/SET_CURRENT_NO", nextNo);
      this.kirimSoal(async () => {
        if (nextSoal.soal.length > 0) {
          await this.$store.commit("ujian/SET_CURRENT_SOAL", nextSoal.soal[0]);
          await this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", nextSoal);
          this.cekSoal();
        } else {
          this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
        }
      });
    },
    prev() {
      this.loading = true;
      let prevNo = this.currentNo - 1;
      let prevIndex = prevNo - 1;
      const prevSoal = this.dataSoal[prevIndex];
      this.$store.commit("ujian/SET_CURRENT_NO", prevNo);
      this.kirimSoal(async () => {
        if (prevSoal.soal.length > 0) {
          await this.$store.commit("ujian/SET_CURRENT_SOAL", prevSoal.soal[0]);
          await this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", prevSoal);
          this.cekSoal();
        } else {
          this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
        }
      });
    },
    akhiriUjian() {
      this.$swal({
        title: "Anda Yakin Mengakhiri Ujian Ini?",
        text: "Jawaban akan otomatis terkirim!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Akhiri",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          this.kirimSoal(() => {
            let payload = {
              hasil_id: this.currentUjian.id,
            };
            this.$store
              .dispatch("ujian/selesaiUjian", payload)
              .then((res) => {
                localStorage.endUjian = true;
                this.loading = false;
                localStorage.removeItem("endtime");
                this.$store.commit("ujian/SET_CURRENT_SOAL", null);
                this.$store.commit("ujian/SET_CURRENT_UJIAN", null);
                this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", null);
                this.$store.commit("ujian/SET_CURRENT_NO", null);
                this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", res.data);
                this.$swal({
                  icon: "success",
                  title: "Anda berhasil mengakhiri ujian ini!",
                  text: "Semoga hasilnya memuaskan :)",
                  allowOutsideClick: true,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                });
                this.$router.push({
                  name: "hasil-ujian",
                  query: { j: this.$route.query?.j },
                });
              })
              .catch((err) => {
                this.loading = false;
                this.displayError(err);
                return false;
              });
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal Mengakhiri",
            text: "Silahkan isi jawaban dengan yakin :)",
            icon: "error",
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
  },
  async created() {
    this.dataSoal = this.currentUjian.user_answer;
    if (this.dataSoal[0] == null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Maaf`,
          icon: "AlertIcon",
          variant: "warning",
          text: "Tidak ada soal di ujian ini!",
        },
      });
      this.$router.push({
        name: "tryout-detail-subtest",
        query: { j: this.tryoutDetail.jadwal_id },
      });
    }
    const firstSoal = this.dataSoal[0].soal[0];
    await this.$store.commit("ujian/SET_CURRENT_SOAL", firstSoal);
    await this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", this.dataSoal[0]);
    await this.$store.commit("ujian/SET_CURRENT_NO", 1);
    await this.setTimer();
    this.cekSoal();
  },
};
</script>

<style></style>
