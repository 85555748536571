var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Contextual states"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeStates) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("<b-form-valid-feedback>")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("<b-form-invalid-feedback>")]), _c('span', [_vm._v(" form sub-components for input validation.")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Valid State",
      "label-for": "input-valid"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-valid",
      "state": true,
      "placeholder": "Valid"
    }
  }), _c('b-form-valid-feedback', [_vm._v(" This is valid state. ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invalid State",
      "label-for": "input-invalid"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-invalid",
      "state": false,
      "placeholder": "Invalid"
    }
  }), _c('b-form-invalid-feedback', [_vm._v(" This is invalid state. ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }